.stickyContainerBottom {
  position: sticky !important;
  bottom: 1rem !important;
  left: 1rem;
}

.toast {
  .header {
    background-color: #ffffffc4;
  }
  .body{
    cursor: pointer;
  }
}
