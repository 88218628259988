.modalDialog {
  .title {
    font-size: 28px;
    text-align: center;
    margin: 0;

    .subTitle {
      font-size: .8em;
      text-align: start;
      color: var(--caption-text-color);
    }
  }

  .body {
    margin: 20px 40px;
    margin-bottom: 0;

    p {
      padding: 0;
      margin: 0;
      margin-top: 1rem;
      font-size: 1rem;
    }
  }

  .footer {
    display: flex;
    justify-content: center;

    button {
      min-width: 100px;
    }

    @media screen and (max-width: 760px) {
      //flex-direction: column;
      width: 100%;
      justify-content: space-evenly;

      button {
        min-width: 120px;
        height: 50px;
      }
    }
  }

  .btnClose {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 5px;
    cursor: pointer;
    font-size: 24px;
    width: 32px;
    height: 32px;
    color: var(--color-hint);

    &:hover {
      color: var(--primary);
    }
  }
}

.modalDialog .btn-close {
  position: absolute !important;
  top: 1.5rem;
  right: 1.5rem;
}

.confirmDialog {
  .body {
    color: var(--color-text);
    font-weight: 600;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0.003em;
  }
}
