.mainMenuList {
  margin: 5px;
  padding: 1px;
  border: #00000020 solid 1px;
  border-radius: 5px;

  .active {
    color: var(--primary);
    font-size: 24px;

    .mainMenuItem {
      background-color: var(--hover-color) !important;

      .icon {
        color: var(--primary);
        opacity: .8;
      }
    }
  }

  .inactive {
    color: var(--black-500);
  }
}

.mainMenuItem {
  color: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  border-radius: 5px;

  .icon {
    color: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    color: var(--primary);
    background-color: var(--hover-color);

    .icon {
      color: var(--primary);
    }
  }
}

.mainMenuList li.mainMenuItem {
  border-top: #00000020 solid 1px;

  &:hover {
    color: var(--primary);
    background-color: var(--hover-color);
  }
}

.mainMenuList a:first-child .mainMenuItem {
  border-top: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.mainMenuList a:last-child .mainMenuItem {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.logOutBtn {
  padding: 10px;
  margin-left: 20px;
  text-underline-offset: 10%;
  font-size: 18px;

  & .mainMenuItem {
    padding: 10px 20px;
    border-radius: 8px !important;
    box-shadow: rgba(116, 139, 167, 0.43) 1px 1px 10px 2px;

  }
}

//@mixin borderTop($items) {
//  @for $i from 0 to length($items) {
//    //@if($i > 1) {
//      #{nth($items, $i + 1)} {
//        color: #7e53ff !important;
//        :after{
//          content: "#{$i}";
//        }
//      //}
//    }
//  }
//
//  //@debug "#{$items}";
//}
//
//.mainMenuList {
//  @include borderTop(".mainMenuItem");
//}
