.toolbarMenu {
  display: flex;
  gap: 3rem;
  font-size: 1.4rem;
  padding: 0 10px;

  .link {
    padding: 20px 0;
    font-weight: 600;
    color: var(--color-text);
    transition: color linear 200ms;
    align-self: center;

    &:hover {
      color: var(--href-color);
    }
  }

  .option {
    cursor: pointer;
    display: flex;
    gap: 1rem;
    padding: 5px 0;
    align-items: center;

    svg {
      display: initial;
    }

    .wrapTitle {
      display: initial;
    }
  }

  .active {
    color: var(--primary);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(90deg, #011f3c 50%, #0d6efd 50%);
    background-size: 200% auto;
    animation: active_anim .4s ease-in forwards;
  }

  .wrapTitle {
  }

  .exp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wrapTitle {
      display: block;
      font-size: 1rem;
    }

    @media screen and (min-width: 1024px) {
      .wrapTitle {
        font-size: inherit;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .link {
      padding: 10px 0;
    }
  }
}

[data-scroll-lock] .toolbarMenu {
  margin-right: var(--scrollbar-compensation);
}

@-webkit-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@-o-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@-moz-keyframes active_anim {
  to {
    background-position: -100% center;
  }
}

@keyframes active_anim {
  to {
    background-position: -100% center;
  }
}
