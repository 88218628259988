p {
    padding-bottom: 20px;
    font-family: 'Gilroy', sans-serif;
    color: var(--main-text-color);
}

a {
    color: var(--href-color);
    font-family: 'Gilroy', sans-serif;
    text-underline-offset: 10%;
    text-decoration: none;
}
