:root {
    --bold: 700;
    --normal: 400;
}

* {
    font-family: Gilroy, sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
    font-style: normal;
    line-height: 1em;
    font-family: 'Gilroy', sans-serif;
    color: var(--color-text);
}

h1 {
    font-weight: var(--bold);
    font-size: 3em;
    margin-bottom: 40px;
}

h2 {
    font-weight: var(--bold);
    font-size: 2.4em;
    margin-bottom: 40px;
}

h3 {
    font-weight: var(--bold);
    font-size: 1.8em;
    margin-bottom: 30px;
}

h4, h5 {
    font-weight: var(--normal);
    font-size: 1.6em;
    margin-bottom: 30px;
}

h6 {
    font-weight: var(--bold);
    font-size: 1.2em;
}

strong {
    color: var(--color-text);
}


@media screen and (max-width: 720px) {
    h1 {
        font-size: 1.8rem;
    }
}


