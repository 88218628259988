*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --background: #f6f6f6;
    --background-color: #ffffff;
    --background-hover: #f6f7f8;
    --caption-text-color: #808080;
    --main-text-color: #797979;
    --input-hint-text-color: #668cb5;
    --href-color: #178CFF;
    --silver-300: #6f7e8b;
    --primary: #0162ff;
    --success: #46b275;
    --warning: #e59b54;
    --danger: #EB5757;
    --error: #ff2727;
    --black-300: #a7a7a7;
    --black-400: #5b5b5b;
    --black-500: #2f2f2f;
    --color-border: rgba(91, 91, 91, 0.3);
    --hover-color: #e5f0fe;
    --color-hint: #B2B7BC;
    --color-text: #001F3D;
    --color-field: #808F9E;
    --color-description2: #5b5b61;
    --color-title-hint: #B3BCC5;
    --bdrs: 12px;
    --color-green: #59eda5;
    --color-green-dark: #31a564;
    --bs-popover-arrow-border: #2f2f2f;
    --color-blocking: rgba(234, 234, 234, 0.5);
    --padding-top-page: 60px;
    --top-margin-page: 90px;
}

