:root {
  /* colors */
  --buy-btn: #D7F51E;
  --blue-btn: linear-gradient(270deg, #50CAFF 0%, #0478FF 100%);
  --default-btn: #ffffff;

  --offcanvas: rgba(111, 126, 140, 0.88); /* выезжающее меню */
  --border-l: #fff;

  /* shadows */
  --shadow-l-btn: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41),
  inset -4px -4px 9px rgba(255, 255, 255, 0.88),
  inset 4px 4px 14px #C1D5EE;
  --shadow-l-btn-h: inset -4px -4px 9px rgba(202, 224, 255, 0.88), inset 4px 4px 14px #C1D5EE;

  --shadow-default-btn: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41);

  --shadow-l-card: 2px 2px 4px rgba(114, 142, 171, 0.1),
  -6px -6px 20px #FFFFFF,
  4px 4px 20px rgba(111, 140, 176, 0.41);

  /* sizes */
  --text-xs: 16px;
  --text-m: 18px;
  --br: 12px;
}

/* system */
.bdrs {
  border-radius: var(--bdrs);
}

body {
  color: var(--main-text-color);
  background-color: var(--background);
  margin: 50px auto 50px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: var(--text-xs);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.6em;
}

label {
  color: var(--caption-text-color);
  font-size: var(--text-xs);
}

button {
  border-radius: var(--br) !important;
  font-weight: 600;
  font-size: var(--text-m) !important;
  line-height: 22px !important;
  height: 42px;
}

.d-grid > label {
  margin-bottom: 20px;
}

.card {
  background-color: var(--background-color);
  /*box-shadow: var(--shadow-l-card);*/
  box-shadow: 0 0 14px -5px #1d69c366;
  border-radius: var(--bdrs);
  padding: 10px;
  border: none !important;
}

.btn, button, a {
  cursor: pointer;
  outline: none !important;
  border: none !important;
  min-width: fit-content;
}

.btn {
  font-style: normal;
  font-weight: 500;
  /*font-size: var(--text-m);*/
  justify-content: center;
  font-size: 14px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
}

.btn-primary.primary {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #0d6efd;
  box-shadow: #4f93f7 0 0 10px 0;
}

.btn-primary.primary:hover {
  opacity: .9;
}

.btn-anim-base {
  color: #fff;
  font-size: 14px !important;
  position: relative !important;
  text-decoration: none;
  overflow: hidden;
  text-transform: uppercase;
  font-weight: 500;
  min-width: 162px;
  transition: all 400ms ease-in-out, color 200ms linear;

  &:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: 11;
  }

  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    transition: all 350ms ease-in-out;
  }

  &:active {
    color: var(--color-text) !important;
    background-color: white !important;
  }
}

.btn-anim-primary {
  @extend .btn-anim-base;
  box-shadow: 0 0 10px 2px rgba(13, 110, 253, 0.5);

  &:hover {
    box-shadow: -5px 0 20px rgba(232, 69, 251, 0.4), 10px 0 20px 2px rgba(19, 248, 242, 0.4);
    background-color: var(--primary);
  }
}

.btn-reject {
  color: var(--primary);
  background-color: #B3D1FF;
  font-style: normal;
  justify-content: center;
  border: #a0bbe5 solid 1px !important;
  font-weight: 500;
  font-size: var(--text-m);
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
}

.btn-reject:hover {
  color: var(--primary);
  background-color: #c1dbff;
}

.btn-primary.remove {
  @extend .btn-anim-base;
  color: white;
  background-color: var(--danger);
  box-shadow: var(--danger) 0 0 10px 0;
}

.btn-primary.remove:active {
  background-color: #d53131;
}

.btn-primary.remove:hover {
  box-shadow: -5px 0 20px rgba(176, 251, 69, 0.4), 10px 0 20px 0 rgba(248, 19, 19, 0.4)
}

.btn-primary.green {
  color: #005c2c;
  background-color: var(--color-green);
  box-shadow: rgba(79, 247, 188, 0.71) 1px 2px 8px 1px
}

.btn-primary.green:active {
  background-color: var(--color-green);
}

.btn-default {
  background: var(--default-btn);
}

.btn-default:hover {
  background-color: #e7e7e7;
}

.btn-info {
  color: var(--silver-300);
  background: var(--default-btn);
}

.btn-blue {
  color: #fff;
  background-color: var(--blue-btn);
}

.btn-buy {
  background-color: var(--buy-btn);
}

.bg-default {
  background-color: var(--default-btn);
}

.bg-blue {
  background-color: var(--blue-btn);
}

.bg-buy {
  background-color: var(--buy-btn);
}

.offcanvas {
  padding: 25px;
  color: #000000;
  font-family: 'Gilroy', sans-serif;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 720px) {
  .offcanvas {
    min-width: -webkit-fill-available;
  }
}

.offcanvas .btn-blue {
  justify-content: start;
  align-items: start;
  color: #fff;
  background-color: #6F7E8C;
  border: inset 1px #E3EDF7;
  padding: 12px 20px;
  box-shadow: inset 1px 1px 4px rgba(18, 40, 67, 0.88), inset -1px -1px 4px rgba(191, 217, 242, 0.88);
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.25)) drop-shadow(-2px -2px 4px rgba(152, 167, 185, 0.25));
}

.btn-white {
  background-color: white;
  color: var(--silver-300);
  box-shadow: rgba(117, 141, 169, 0.43) 1px 1px 10px 2px;
}

.btn-white:hover {
  color: var(--primary);
  background-color: rgb(225, 237, 255);
}

.btn-white:active {
  color: rgba(18, 109, 245, 0.1);
  background-color: Var(--primary)
}

.menu_btn {
  margin: 10px;
  width: 40px;
  height: 40px;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.btnMenuClose {
  position: absolute;
  top: 30px;
  right: 30px;
}

.menu_btn:hover {
  color: var(--primary);
  background-color: rgb(225, 237, 255);
}

.auth_form {
  background: var(--background-color);
  box-shadow: 2px 2px 4px rgba(114, 142, 171, 0.1), -6px -6px 20px #FFFFFF, 4px 4px 20px rgba(111, 140, 176, 0.41);
  border-radius: 8px;
}

.auth_form form > div {
  margin-bottom: 20px;
}

.auth_form .btn-blue {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.auth_form .btn-blue:hover {
  color: #ffffff;
}

.hideBlock {

}

.mobileBlock {
  display: none;
  overflow: hidden;
  object-fit: cover;
  border-radius: var(--br);
}

.mobileBlock img {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .mobileBlock {
    display: block;
  }
}

.pointer {
  cursor: pointer !important;
}

.blocked {
  cursor: wait !important;
  background-color: rgba(72, 72, 72, 0.1);
}

.offcanvas-backdrop {
  width: 100%;
  height: 100%;
}

.offcanvas-body {
  font-size: 1.2rem;
}

.fade.modal-backdrop.show {
  width: 100%;
  height: 100%;
}

.wrapperResponseDevice {
  display: flex;
  width: 100%;
  padding-top: 5px;
  flex-wrap: wrap;
  gap: .6rem;
  justify-content: space-between;
  align-items: flex-end;
}

.bsGutterY {
  --bs-gutter-y: 1rem;
}

.br {
  border-radius: var(--br);
}

.errorValidateBlock {
  color: var(--danger);
}

.accordion {
  --bs-accordion-border-radius: 12px;
}

.accordion .accordion-item {
  padding: 3px;
}

.accordion-header button {
  padding: 0 10px !important;
  border-radius: 0 !important;
  height: 30px;
}

.disableElem {
  pointer-events: none;
  opacity: .8;
}


/*override styles*/
.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.flex-1 {
  flex: 1;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  //backdrop-filter: blur(8px);
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px) !important;
  backdrop-filter: blur(30px) saturate(100%) contrast(150%);

  @media screen and (max-width: 420px) {
    backdrop-filter: none;
  }
}

.requiredField {
  &:after {
  content: '*';
    color: var(--danger);
    font-weight: 600;
    font-size: 1.5rem;
    padding-left: 5px;
  }
}

.scrollWrap {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 50vh;

  &::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: rgba(54, 108, 192, 0.28);
    border-radius: .5rem;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #71a6ff;
    border-radius: .5rem;
  }
}

