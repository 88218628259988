.toolbar {
  background: white;
  display: flex;
  justify-content: center;
  width: 100% !important;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.14);

  .wrapElements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 20px;
    z-index: 12;
  }

  .divider {
    width: 75%;
    height: 1px;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    opacity: .3;
  }

  @media screen and (max-width: 960px) {
    height: auto !important;
  }
}

.fadeIn {
  opacity: 1;

}

.sticky {
  opacity: 0;
}
