.mainContainer {
  margin: 0 auto !important;
  background-color: var(--background-color);
  border-radius: var(--bdrs);
  min-height: 100vh;
}

.appFooter {
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  justify-content: center;
  bottom: 0;
  gap: 1rem;
  margin: 20px 50px;

  a {
    color: var(--color-field);
  }

  a:hover {
    color: var(--primary) !important;
  }
}

.logoWrap {
  padding: 10px 0;

  .mainLogo {
    opacity: 1;
    height: 70px;
    transition: 0.4s;
    overflow: hidden;

    @media screen and (max-width: 960px) {
      height: 50px;
    }
  }
}
